import { Record, Union } from "../fable_modules/fable-library.4.10.0/Types.js";
import { option_type, class_type, bool_type, string_type, record_type, union_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { User_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { render as render_1, update as update_1, init as init_1, Msg_$reflection as Msg_$reflection_1, State_$reflection as State_$reflection_1 } from "../Components/ShipmentDashboard.fs.js";
import { Cmd_none, Cmd_map } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { printf, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library.4.10.0/List.js";

export class SelectableTabs extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Billing", "Shipment"];
    }
}

export function SelectableTabs_$reflection() {
    return union_type("CView.UI.Pages.Dashboard.SelectableTabs", [], SelectableTabs, () => [[], []]);
}

export class State extends Record {
    constructor(User, ActiveTab, ShipmentDashboard) {
        super();
        this.User = User;
        this.ActiveTab = ActiveTab;
        this.ShipmentDashboard = ShipmentDashboard;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.Dashboard.State", [], State, () => [["User", User_$reflection()], ["ActiveTab", SelectableTabs_$reflection()], ["ShipmentDashboard", State_$reflection_1()]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoOp", "Search", "ShipmentUnpinned"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.Dashboard.Intent", [], Intent, () => [[], [["Item1", string_type], ["Item2", string_type], ["Item3", bool_type], ["Item4", bool_type], ["Item5", option_type(class_type("System.DateTime"))]], [["Item", class_type("System.Guid")]]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetActiveTab", "ShipmentDashboardMsg"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.Dashboard.Msg", [], Msg, () => [[["Item", SelectableTabs_$reflection()]], [["Item", Msg_$reflection_1()]]]);
}

export function init(user, accounts) {
    const patternInput = init_1(user, accounts);
    return [new State(user, user.CanViewShipments ? (new SelectableTabs(1, [])) : (new SelectableTabs(0, [])), patternInput[0]), Cmd_map((Item) => (new Msg(1, [Item])), patternInput[1])];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        const patternInput = update_1(msg.fields[0], state.ShipmentDashboard);
        const shipmentDashboardMsgIntent = patternInput[2];
        const intent = (shipmentDashboardMsgIntent.tag === 1) ? (new Intent(1, [shipmentDashboardMsgIntent.fields[0], shipmentDashboardMsgIntent.fields[1], shipmentDashboardMsgIntent.fields[2], shipmentDashboardMsgIntent.fields[3], shipmentDashboardMsgIntent.fields[4]])) : ((shipmentDashboardMsgIntent.tag === 2) ? (new Intent(2, [shipmentDashboardMsgIntent.fields[0]])) : (new Intent(0, [])));
        return [new State(state.User, state.ActiveTab, patternInput[0]), Cmd_map((Item) => (new Msg(1, [Item])), patternInput[1]), intent];
    }
    else {
        return [new State(state.User, msg.fields[0], state.ShipmentDashboard), Cmd_none(), new Intent(0, [])];
    }
}

export function render(state, dispatch) {
    let elems_4, elems_2, children, elems_3;
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_4 = [createElement("div", createObj(ofArray([["id", "main_tabs"], ["className", join(" ", ["tabs", "is-small", "fixed-tabs"])], (elems_2 = [(children = toList(delay(() => {
        let elems;
        return append(state.User.CanViewShipments ? singleton(createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("has-text-weight-semibold"), delay(() => append(singleton("tablink"), delay(() => ((state.ActiveTab.tag === 1) ? singleton("is-active") : singleton("")))))))))], (elems = [createElement("a", {
            onClick: (_arg) => {
                dispatch(new Msg(0, [new SelectableTabs(1, [])]));
            },
            children: "Shipments",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : empty(), delay(() => {
            let elems_1, fmt;
            return state.User.CanViewBilling ? singleton(createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("has-text-weight-semibold"), delay(() => append(singleton("tablink"), delay(() => (equals(state.ActiveTab, new SelectableTabs(0, [])) ? singleton("is-active") : empty()))))))))], (elems_1 = [createElement("a", createObj(ofArray([["onClick", (_arg_1) => {
                dispatch(new Msg(0, [new SelectableTabs(0, [])]));
            }], (fmt = printf("Billing"), fmt.cont((value_11) => ["children", value_11]))])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty();
        }));
    })), createElement("ul", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", "tabcontainer"], ["id", "dashboards"], (elems_3 = toList(delay(() => ((state.ActiveTab.tag === 0) ? singleton(createElement("div", {})) : singleton(render_1(state.ShipmentDashboard, (arg) => {
        dispatch(new Msg(1, [arg]));
    }))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

